import { createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors';

// Utility function to handle errors
const handleError = (error) =>
  error.response?.data || error.message || 'An error occurred';

// Utility function to ensure `property_id` format
const formatPropertyId = (propertyId) => {
  if (!propertyId.startsWith('properties/')) {
    return `properties/${propertyId}`;
  }
  return propertyId;
};

// Utility function to process API response
const processApiResponse = (data) => {
  // Ensure dimensions and metrics are in user-friendly format
  const dimensions = data.dimensions.map((dim) => ({
    original: dim.original,
    friendly: dim.friendly,
  }));

  const metrics = data.metrics.map((metric) => ({
    original: metric.original,
    friendly: metric.friendly,
  }));

  return {
    ...data,
    dimensions,
    metrics,
    chartData: data.chart_data || [], // Ensure chart data is properly included
  };
};

// Generic async thunk to fetch module data
export const fetchBaseModuleData = createAsyncThunk(
  'analytics/fetchData',
  async (
    { moduleName, startDate, endDate, prevStartDate, prevEndDate },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');
      const formattedPropertyId = formatPropertyId(propertyId);

      // Fetch data from API
      const response = await backendAxiosInstance.get(`/api/ga4/${moduleName}/`, {
        params: {
          property_id: formattedPropertyId,
          start_date: startDate,
          end_date: endDate,
          prev_start_date: prevStartDate,
          prev_end_date: prevEndDate,
        },
      });

      // Process response for Redux state
      const processedData = processApiResponse(response.data);

      return { moduleName, data: processedData };
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Async thunk to fetch data for a specific visualization (chart or table)
export const fetchVisualizationData = createAsyncThunk(
  'analytics/fetchVisualizationData',
  async (
    { moduleName, visualizationType, startDate, endDate, prevStartDate, prevEndDate, dimension, metric },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');
      const formattedPropertyId = formatPropertyId(propertyId);

      // Fetch data from API for a specific visualization
      const response = await backendAxiosInstance.get(`/api/ga4/${moduleName}/${visualizationType}/`, {
        params: {
          property_id: formattedPropertyId,
          start_date: startDate,
          end_date: endDate,
          prev_start_date: prevStartDate,
          prev_end_date: prevEndDate,
          dimension,
          metric,
        },
      });

      // Include visualization-specific data in the Redux state
      return {
        moduleName,
        visualizationType,
        dimension,
        metric,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);
