import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const AcquisitionPage = () => (
  <BaseAnalyticsComponent
    moduleName="acquisition"
    moduleTitle="Acquisition Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default AcquisitionPage;
