import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice'; // Assuming you have a logout action in authSlice
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Make a request to your Django backend to invalidate the JWT (if needed)
        await axiosInstance.post('/api/account/auth/logout/', {
          // Optionally, send refresh token if required by your backend
          refresh_token: localStorage.getItem('refreshToken'),
        });

        // Clear tokens from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Dispatch the logout action to clear the Redux state
        dispatch(logout());

        // Redirect to the login page or home page
        navigate('/login');
      } catch (error) {
        console.error('Logout failed:', error);
        // Optionally handle logout failure (e.g., show a notification)
        navigate('/login'); // Ensure user is still redirected to login
      }
    };

    // Perform the logout when the component mounts
    performLogout();
  }, [dispatch, navigate]);

  return (
    <div>
      <h2>Logging you out...</h2>
    </div>
  );
};

export default LogoutPage;
