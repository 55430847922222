// src/components/TokenUsage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TokenUsage = () => {
  const [tokenData, setTokenData] = useState({
    fixed_tokens_remaining: 0,
    totalFixedAllowance: 0,
    fixedTokenPerce: 0,
  });

  // Fetch token data on component mount
  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.get('/api/get_token_count/');
        const data = response.data;
        
        // Calculate total allowance and percentage
        const fixed_tokens_remaining = data.fixed_tokens_remaining || 0;
        const totalFixedAllowance = (data.fixed_token_allowance || 0) + (data.manual_tokens || 0) + (data.tokens_purchased || 0);
        const fixedTokenPerce = totalFixedAllowance 
          ? 100 - Math.round((fixed_tokens_remaining * 100) / totalFixedAllowance) 
          : 0;

        setTokenData({
          fixed_tokens_remaining,
          totalFixedAllowance,
          fixedTokenPerce,
        });
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };
    
    fetchTokenData();
  }, []);

  // Helper function to format numbers with commas
  const numberWithCommas = (x) => x.toLocaleString();

  return (
    <div className="token-usage token-usage2">
      <div className="token-bar">
        <div
          className="token-bar-used"
          style={{ width: `${tokenData.fixedTokenPerce}%` }}
        ></div>
        <span className="token-bar-text">
          {`${tokenData.fixedTokenPerce}% tokens used`}
        </span>
      </div>
      <div className="token-count">
        {`Tokens remaining: ${numberWithCommas(tokenData.fixed_tokens_remaining)} / ${numberWithCommas(tokenData.totalFixedAllowance)}`}
      </div>
    </div>
  );
};

export default TokenUsage;
