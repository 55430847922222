/**
 * Formats a number with commas and fixed decimal places.
 * @param {number} value - The number to format.
 * @param {number} decimalPlaces - The number of decimal places to include.
 * @param {string} locale - Optional locale string (e.g., 'en-US').
 * @returns {string} - The formatted number as a string, or '-' if the value is invalid.
 */
export const formatDecimal = (value, decimalPlaces = 2, locale = 'en-US') => {
  if (value === null || value === undefined || typeof value !== 'number' || isNaN(value)) {
    return '-';
  }
  return value.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

/**
 * Formats an integer with commas for thousands.
 * @param {number} value - The integer to format.
 * @param {string} locale - Optional locale string (e.g., 'en-US').
 * @returns {string} - The formatted integer as a string, or '-' if the value is invalid.
 */
export const formatInteger = (value, locale = 'en-US') => {
  return formatDecimal(value, 0, locale);
};

/**
 * Formats a percentage value.
 * @param {number} value - The percentage to format (0.0 - 1.0 for 0% - 100%).
 * @param {number} decimalPlaces - Number of decimal places.
 * @returns {string} - The formatted percentage.
 */
export const formatPercentage = (value, decimalPlaces = 2) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '-';
  }
  return `${(value * 100).toFixed(decimalPlaces)}%`;
};

/**
 * Formats duration from seconds to 'm:ss' format.
 * @param {number} seconds - The duration in seconds.
 * @returns {string} - The formatted duration as a string.
 */
export const formatDuration = (seconds) => {
  if (seconds === null || seconds === undefined || isNaN(seconds)) {
    return '-';
  }
  const mins = Math.floor(seconds / 60);
  const secs = Math.round(seconds % 60);
  return `${mins}m ${secs}s`;
};

/**
 * Automatically formats a number based on its type.
 * - Integers are formatted as whole numbers.
 * - Decimal values as fixed decimal points.
 * - Percentages (values between 0 and 1) are formatted with '%' suffix.
 * - Durations in seconds with 'm:ss' format if applicable.
 * @param {number} value - The value to format.
 * @param {string} type - The type of the number (e.g., 'integer', 'decimal', 'percentage', 'duration').
 * @param {number} decimalPlaces - Optional number of decimal places.
 * @returns {string} - The formatted value as a string.
 */
export const formatNumber = (value, type = 'decimal', decimalPlaces = 2) => {
  switch (type) {
    case 'integer':
      return formatInteger(value);
    case 'decimal':
      return formatDecimal(value, decimalPlaces);
    case 'percentage':
      return formatPercentage(value, decimalPlaces);
    case 'duration':
      return formatDuration(value);
    default:
      return formatDecimal(value, decimalPlaces); // Fallback to decimal
  }
};

/**
 * Formats a date string based on the desired format.
 * @param {string} dateString - The date string to format (supports 'YYYYMMDD', 'YYYY-MM-DD', or standard Date strings).
 * @param {string} format - The desired format for the output (e.g., 'MMM DD, YYYY', 'YYYY-MM', 'MMM YYYY').
 * @param {string} locale - The locale for formatting (default is 'en-US').
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateString, format = 'MMM DD, YYYY', locale = 'en-US') => {
  let date;

  // Handle 'YYYYMMDD' or 'YYYY-MM-DD' format strings
  if (/^\d{8}$/.test(dateString)) {
    const year = parseInt(dateString.slice(0, 4), 10);
    const month = parseInt(dateString.slice(4, 6), 10) - 1;
    const day = parseInt(dateString.slice(6, 8), 10);
    date = new Date(year, month, day);
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    date = new Date(dateString);
  } else {
    date = new Date(dateString); // Fallback for standard date formats
  }

  // Check for invalid dates
  if (isNaN(date.getTime())) {
    return '-';
  }

  // Define formatting options based on the specified format
  const options = {};
  if (format.includes('YYYY')) options.year = 'numeric';
  if (format.includes('MMM')) options.month = 'short';
  else if (format.includes('MM')) options.month = '2-digit';
  if (format.includes('DD')) options.day = '2-digit';

  return date.toLocaleDateString(locale, options);
};
