// src/features/ga4/accountPropertySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance'; // Use backendAxiosInstance

// Fetch account and property pairs through the backend proxy
export const fetchAccountPropertyPairs = createAsyncThunk(
  'accountProperty/fetchAccountPropertyPairs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.get('/api/ga4/account_property_pairs/'); // Proxy endpoint
      console.log('Fetched Account-Property Pairs:', response.data); // Debugging fetched pairs
      return response.data;
    } catch (error) {
      console.error('Error fetching account-property pairs:', error.response || error);
      return rejectWithValue(error.response?.data || 'Failed to fetch account-property pairs');
    }
  }
);

// Select account and property through the backend proxy
export const selectAccountProperty = createAsyncThunk(
  'accountProperty/selectAccountProperty',
  async ({ selectedAccount, selectedProperties }, { rejectWithValue, dispatch }) => {
    try {
      console.log('Dispatching selectAccountProperty with:', { selectedAccount, selectedProperties }); // Debugging selected values
      const response = await backendAxiosInstance.post('/api/ga4/select_property/', {
        selected_account: selectedAccount,
        selected_properties: selectedProperties,
      });

      // Persist the selected property ID in local storage
      const propertyId = selectedProperties[0];
      const selectedAccountProperty = { selectedAccount, propertyId };
      localStorage.setItem('selectedAccountProperty', JSON.stringify(selectedAccountProperty));
      localStorage.setItem('selectedAccount', selectedAccount);

      // Update Redux state
      dispatch(setPropertyId(propertyId));
      dispatch(setSelectedAccount(selectedAccount));

      return response.data;
    } catch (error) {
      console.error('Error selecting account-property:', error.response || error);
      return rejectWithValue(error.response?.data || 'Failed to select account-property');
    }
  }
);

const initialState = {
  accountPropertyPairs: [],
  selectedAccount: JSON.parse(localStorage.getItem('selectedAccountProperty'))?.selectedAccount || null,
  selectedProperties: JSON.parse(localStorage.getItem('selectedAccountProperty'))?.propertyId
    ? [JSON.parse(localStorage.getItem('selectedAccountProperty')).propertyId]
    : [],
  propertyId: JSON.parse(localStorage.getItem('selectedAccountProperty'))?.propertyId || null,
  tier: null,
  status: 'idle',
  error: null,
  successMessage: null,
};

const accountPropertySlice = createSlice({
  name: 'accountProperty',
  initialState,
  reducers: {
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    clearSelection: (state) => {
      state.selectedAccount = null;
      state.selectedProperties = [];
      state.propertyId = null;
      localStorage.removeItem('selectedAccountProperty');
      localStorage.removeItem('selectedAccount');
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching account-property pairs
      .addCase(fetchAccountPropertyPairs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(fetchAccountPropertyPairs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accountPropertyPairs = action.payload.account_property_pairs || [];
        state.tier = action.payload.user_tier || 'Basic';
      })
      .addCase(fetchAccountPropertyPairs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch account-property pairs';
      })

      // Handle selecting an account-property
      .addCase(selectAccountProperty.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(selectAccountProperty.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedAccount = action.meta.arg.selectedAccount;
        state.selectedProperties = action.meta.arg.selectedProperties;
        state.propertyId = action.meta.arg.selectedProperties[0]; // Update propertyId in state
        console.log('Selected Account in State:', state.selectedAccount);
        console.log('Selected Properties in State:', state.selectedProperties); // Debugging state
        state.successMessage = action.payload.message || 'Properties connected successfully!';
      })
      .addCase(selectAccountProperty.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to select account-property';
      });
  },
});

export const { setPropertyId, setSelectedAccount, clearSelection } = accountPropertySlice.actions;
export default accountPropertySlice.reducer;
