import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance'; 

// Async action to check GA4 authorization
export const checkAuthorization = createAsyncThunk(
  'ga4/checkAuthorization',
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.get('/api/ga4/check_authorization/');
      return response.data; // Return data if authorization is successful
    } catch (error) {
      if (error.response) {
        // Return the error response in case of failure
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

const ga4Slice = createSlice({
  name: 'ga4',
  initialState: {
    properties: [],
    accounts: [],
    hasGa4Property: false,
    loading: false,
    error: null,
  },
  reducers: {
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    resetGa4State: (state) => {
      state.properties = [];
      state.accounts = [];
      state.hasGa4Property = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state for checking authorization
      .addCase(checkAuthorization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle fulfilled state for checking authorization
      .addCase(checkAuthorization.fulfilled, (state, action) => {
        state.loading = false;
        // If authorized, update hasGa4Property based on the response
        if (action.payload.message === 'Authorized successfully.') {
          state.hasGa4Property = true;
        } else {
          state.hasGa4Property = false;
        }
      })
      // Handle rejected state for checking authorization
      .addCase(checkAuthorization.rejected, (state, action) => {
        state.loading = false;
        state.hasGa4Property = false;
        state.error = action.payload || 'Failed to check authorization.';
      });
  },
});

export const { setProperties, setAccounts, resetGa4State } = ga4Slice.actions;
export default ga4Slice.reducer;
