import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for redirection
import { fetchAccountsAndProperties, submitSelectedProperties, togglePropertySelection } from '../../features/ga4/ga4ConnectSlice';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Checkbox, Skeleton, Box } from '@mui/material';

const AccountPropertyConnectPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts, selectedProperties, tier, tierLimit, status, error, successMessage } = useSelector(state => state.ga4Connect);

  const [maxProperties, setMaxProperties] = useState(1); // Default limit until the data is fetched
  const [isSubmitting, setIsSubmitting] = useState(false); // Local loading state for form submission

  // Set maxProperties dynamically based on the API response
  useEffect(() => {
    if (tierLimit) {
      setMaxProperties(tierLimit);
    }
  }, [tierLimit]);

  // Fetch accounts and properties on component mount
  useEffect(() => {
    dispatch(fetchAccountsAndProperties());
  }, [dispatch]);

  const handlePropertySelection = (propertyId, connected) => {
    if (connected) {
      alert("This property is already connected to So What Labs and cannot be unselected.");
      return;
    }

    if (selectedProperties.length < maxProperties || selectedProperties.includes(propertyId)) {
      dispatch(togglePropertySelection(propertyId));
    } else {
      alert(`You can only select up to ${maxProperties} properties in your current plan.`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set local loading state

    // Perform navigation immediately while submission happens in the background
    navigate('/select-account-property');

    // Submit selected properties in the background
    dispatch(submitSelectedProperties(selectedProperties))
      .finally(() => {
        setIsSubmitting(false);
      })
      .catch((err) => console.error('Error submitting selected properties:', err));
  };

  // Skeleton Loader for the table when loading data
  const renderSkeleton = () => {
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} variant="rectangular" height={40} sx={{ mb: 1 }} />
        ))}
      </Box>
    );
  };

  // Data for the DataGrid
  const rows = accounts.flatMap(account =>
    account.property_list.map(property => ({
      id: property.id,
      accountName: account.name,
      propertyName: property.name,
      connected: property.connected ? 'Connected' : 'Not Connected', // Flag whether the property is connected
    }))
  );

  const columns = [
    { field: 'accountName', headerName: 'Account', width: 200 },
    { field: 'propertyName', headerName: 'Property', width: 300 },
    {
      field: 'connected',
      headerName: 'Connection Status',
      width: 200,
      renderCell: (params) => (
        <span>{params.value}</span>
      )
    },
    {
      field: 'select',
      headerName: 'Select Property',
      width: 200,
      renderCell: (params) => (
        <Checkbox
          checked={selectedProperties.includes(params.row.id)}
          onChange={() => handlePropertySelection(params.row.id, params.row.connected === 'Connected')} // Prevent unselecting connected properties
          disabled={params.row.connected === 'Connected'} // Disable checkbox if the property is already connected
        />
      )
    },
  ];

  return (
    <div className="user-screen">
      <div className="content-users big">
        <h2 className="text-center">Connect Your Google Analytics 4 Properties</h2>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <p><strong>Note:</strong> You are in the {tier} plan. You can select up to {maxProperties} properties.</p>
        <p>{selectedProperties.length} out of {maxProperties} properties selected.</p>

        <form onSubmit={handleSubmit}>
          <div style={{ height: 400, width: '100%' }}>
            {/* Render skeleton loader if the status is loading */}
            {status === 'loading' ? (
              renderSkeleton()
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                checkboxSelection={false} // We are handling the checkbox manually in the select column
              />
            )}
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="btn btn--block"
            style={{ marginTop: '20px' }}
            disabled={isSubmitting} // Disable button when submitting
          >
            {isSubmitting ? 'Submitting...' : 'Connect to So What Labs'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AccountPropertyConnectPage;
