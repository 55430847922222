import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import axiosInstance from '../../services/axiosInstance';
import { formatNumber, formatDuration } from '../../utils/numberUtils';

const OverviewPage = () => {
  const [timeframe, setTimeframe] = useState('last_7_days');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Access selected properties from Redux
  const { selectedProperties } = useSelector((state) => state.accountProperty);

  const endpointMap = {
    last_7_days: 'last-7-days',
    last_30_days: 'last-30-days',
    last_90_days: 'last-90-days',
    this_year: 'this-year',
  };

  useEffect(() => {
    if (selectedProperties.length > 0) {
      updateDashboard(timeframe);
    }
  }, [timeframe, selectedProperties]);

  const updateDashboard = async (selectedTimeframe) => {
    setLoading(true);
    const endpoint = endpointMap[selectedTimeframe];
    const property_id = selectedProperties[0]; // Use the first selected property
  
    try {
      const response = await axiosInstance.get(`/api/ga4/analytics/${endpoint}/`, {
        params: { property_id }, // Pass property_id as expected by the backend
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
    }
    setLoading(false);
  };

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  return (
    <div className="container">
      <div className="main">
        <Sidebar />
        <Navbar />
        <div className="content">
          <div className="title-filter">
            <Typography variant="h4" gutterBottom>So What's Going On?</Typography>
            <div className="select-group">
              <Typography variant="body1">Select Timeframe</Typography>
              <Select value={timeframe} onChange={handleTimeframeChange}>
                <MenuItem value="last_7_days">Last 7 Days</MenuItem>
                <MenuItem value="last_30_days">Last 30 Days</MenuItem>
                <MenuItem value="last_90_days">Last 90 Days</MenuItem>
                <MenuItem value="this_year">This Year</MenuItem>
              </Select>
            </div>
          </div>

          {loading ? (
            <CircularProgress />
          ) : (
            data && (
              <div>
                <div className="text-container">
                  <Typography variant="h6">Traffic</Typography>
                  <Typography>{data.insights.traffic_analysis}</Typography>
                  <Typography variant="h6">Engagement</Typography>
                  <Typography>{data.insights.engagement_analysis}</Typography>
                  <Typography variant="h6">Conversions</Typography>
                  <Typography>{data.insights.conversion_analysis}</Typography>
                </div>

                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="Analytics Table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Active Users</TableCell>
                        <TableCell>Sessions</TableCell>
                        <TableCell>Bounce Rate</TableCell>
                        <TableCell>Avg. Session Duration</TableCell>
                        <TableCell>Pageviews/Session</TableCell>
                        <TableCell>Conversions</TableCell>
                        <TableCell>Total Revenue</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.dimensions[0]}</TableCell> {/* Directly display formatted date */}
                          <TableCell>{formatNumber(row.metrics[0])}</TableCell>
                          <TableCell>{formatNumber(row.metrics[1])}</TableCell>
                          <TableCell>{(parseFloat(row.metrics[2]) * 100).toFixed(2) + '%'}</TableCell>
                          <TableCell>{formatDuration(row.metrics[3])}</TableCell>
                          <TableCell>{parseFloat(row.metrics[4]).toFixed(2)}</TableCell>
                          <TableCell>{parseFloat(row.metrics[5]).toFixed(2)}</TableCell>
                          <TableCell>{parseFloat(row.metrics[6]).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
