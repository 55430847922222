// src/features/ga4/audienceOverviewSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors'; // Import the selector

// Helper function for error handling
const handleError = (error) => error.response?.data || error.message || 'An error occurred';

// Utility function to format property ID
const formatPropertyId = (propertyId) => {
  // Remove 'properties/' prefix if present
  if (propertyId.startsWith('properties/')) {
    return propertyId.replace('properties/', '');
  }
  return propertyId;
};

// Async Thunks
export const fetchAudienceData = createAsyncThunk(
  'audienceOverview/fetchAudienceData',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');

      // Format the property ID to remove 'properties/' prefix
      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/audience-overview/', {
        params: {
          property_id: formattedPropertyId,
          start_date: startDate,
          end_date: endDate,
          prev_start_date: prevStartDate,
          prev_end_date: prevEndDate,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchQA = createAsyncThunk(
  'audienceOverview/fetchQA',
  async ({ dates, userQuestion }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');

      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/audience-qa/', {
        params: {
          property_id: formattedPropertyId,
          start_date: dates.startDate,
          end_date: dates.endDate,
          user_question: userQuestion,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchSummary = createAsyncThunk(
  'audienceOverview/fetchSummary',
  async ({ dates }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');

      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/audience-summary/', {
        params: {
          property_id: formattedPropertyId,
          start_date: dates.startDate,
          end_date: dates.endDate,
        },
      });
      return response.data.summary;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchInsights = createAsyncThunk(
  'audienceOverview/fetchInsights',
  async ({ dates }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');

      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/audience-insights/', {
        params: {
          property_id: formattedPropertyId,
          start_date: dates.startDate,
          end_date: dates.endDate,
        },
      });
      return response.data.insights;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchRecommendations = createAsyncThunk(
  'audienceOverview/fetchRecommendations',
  async ({ dates }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');

      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/audience-recommendations/', {
        params: {
          property_id: formattedPropertyId,
          start_date: dates.startDate,
          end_date: dates.endDate,
        },
      });
      return response.data.recommendations;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Slice definition
const audienceOverviewSlice = createSlice({
  name: 'audienceOverview',
  initialState: {
    audienceData: null,
    qaData: null,
    summaryData: null,
    insightsData: null,
    recommendationsData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Audience Overview Data
      .addCase(fetchAudienceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAudienceData.fulfilled, (state, action) => {
        state.loading = false;
        state.audienceData = action.payload;
      })
      .addCase(fetchAudienceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Q&A Data
      .addCase(fetchQA.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQA.fulfilled, (state, action) => {
        state.loading = false;
        state.qaData = action.payload.conversation_history || [];
      })
      .addCase(fetchQA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Summary Data
      .addCase(fetchSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryData = action.payload;
      })
      .addCase(fetchSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Insights Data
      .addCase(fetchInsights.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInsights.fulfilled, (state, action) => {
        state.loading = false;
        state.insightsData = action.payload;
      })
      .addCase(fetchInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Recommendations Data
      .addCase(fetchRecommendations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.loading = false;
        state.recommendationsData = action.payload;
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default audienceOverviewSlice.reducer;
