import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const DevicePage = () => (
  <BaseAnalyticsComponent
    moduleName="device"
    moduleTitle="Device Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default DevicePage;
