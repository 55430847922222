import React, { useState } from 'react';
import ga4Icon from '../../assets/img/ico-ga4c.svg'; // Adjust the path as needed
import axiosInstance from '../../services/axiosInstance'; // axiosInstance with JWT token management

const ConnectGa4AccountPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleConnectClick = async () => {
    setLoading(true);
    setError(null);

    try {
      // Make an authenticated request to get the authorization URL
      const response = await axiosInstance.get('/authorize/');

      if (response.status === 200 && response.data.authorization_url) {
        // Redirect the user to the Google OAuth consent screen
        window.location.href = response.data.authorization_url;
      } else {
        setError('Failed to initiate OAuth flow. Please try again.');
      }
    } catch (error) {
      console.error('Error during authorization request:', error);
      setError('An error occurred while connecting. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-screen">
      <div className="content-users">
        <div className="connect-ga4-account-page">
          <h2>Connect to So What Labs</h2>
          <div className="source__info source__connect">
            <div className="source__image">
              <img src={ga4Icon} alt="GA4 Icon" />
            </div>
            <div className="source__name">
              <h4>Google Analytics 4</h4>
              <p>Tracking system and Analytics platform</p>
            </div>
            <div className="btns">
              <button
                onClick={handleConnectClick}
                className="btn btn--block"
                disabled={loading}
              >
                {loading ? 'Connecting...' : 'Connect to So What Labs'}
              </button>
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectGa4AccountPage;
