import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';

// Helper function for parameter construction
const constructParams = ({ propertyId, startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }) => {
  const params = {
    property_id: propertyId,
    start_date: startDate,
    end_date: endDate,
  };

  if (isComparisonEnabled) {
    params.prev_start_date = prevStartDate;
    params.prev_end_date = prevEndDate;
  }

  return params;
};

// Helper function for API calls
const fetchData = async (url, params, rejectWithValue) => {
  try {
    const response = await backendAxiosInstance.get(url, { params });
    console.log(`Fetched data from ${url} with params:`, params);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error.response?.data || error.message);
    return rejectWithValue(error.response?.data || 'Failed to fetch data');
  }
};

// Async Thunks
export const fetchConversionsDailyData = createAsyncThunk(
  'conversionsChart/fetchDailyData',
  async (params, { rejectWithValue }) =>
    fetchData('/api/ga4/conversions/daily', constructParams(params), rejectWithValue)
);

export const fetchConversionsWeeklyData = createAsyncThunk(
  'conversionsChart/fetchWeeklyData',
  async (params, { rejectWithValue }) =>
    fetchData('/api/ga4/conversions/weekly', constructParams(params), rejectWithValue)
);

export const fetchConversionsMonthlyData = createAsyncThunk(
  'conversionsChart/fetchMonthlyData',
  async (params, { rejectWithValue }) =>
    fetchData('/api/ga4/conversions/monthly', constructParams(params), rejectWithValue)
);

// Slice definition
const conversionsChartSlice = createSlice({
  name: 'conversionsChart',
  initialState: {
    currentPeriodData: null,
    previousPeriodData: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    clearData(state) {
      state.currentPeriodData = [];
      state.previousPeriodData = [];
    },
  },
  extraReducers: (builder) => {
    const setPending = (state) => {
      state.status = 'loading';
      state.error = null;
    };

    const setFulfilled = (state, action) => {
      state.status = 'succeeded';
      state.currentPeriodData = action.payload.current_period;
      state.previousPeriodData = action.payload.previous_period || [];
    };

    const setRejected = (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    };

    // Daily data
    builder
      .addCase(fetchConversionsDailyData.pending, setPending)
      .addCase(fetchConversionsDailyData.fulfilled, setFulfilled)
      .addCase(fetchConversionsDailyData.rejected, setRejected);

    // Weekly data
    builder
      .addCase(fetchConversionsWeeklyData.pending, setPending)
      .addCase(fetchConversionsWeeklyData.fulfilled, setFulfilled)
      .addCase(fetchConversionsWeeklyData.rejected, setRejected);

    // Monthly data
    builder
      .addCase(fetchConversionsMonthlyData.pending, setPending)
      .addCase(fetchConversionsMonthlyData.fulfilled, setFulfilled)
      .addCase(fetchConversionsMonthlyData.rejected, setRejected);
  },
});

export const { clearData } = conversionsChartSlice.actions;
export default conversionsChartSlice.reducer;
