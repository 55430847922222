import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const products = [
  {
    path: "/home",
    icon: "/assets/img/icon-analyst-mono.svg",
    title: "Web Analyst",
    description: "Ask a question, get insights and recommendations on your Google Analytics data."
  },
  {
    path: "/crawl",
    icon: "/assets/img/icon-insights-mono.svg",
    title: "SEO Specialist",
    description: "Get site audits, recommendations, organic traffic analysis, and position ranking."
  },
  {
    path: "/ga4_content",
    icon: "/assets/img/icon-content-mono.svg",
    title: "Content Creator",
    description: "Provide your site URL and the app writes contextual content for you."
  },
  {
    path: "/business_insights",
    icon: "/assets/img/icon-insights-mono.svg",
    title: "Insights Developer",
    description: "Based on your business context, the app develops actionable insights in seconds."
  },
  {
    path: "/business_recommendations",
    icon: "/assets/img/icon-strategy-mono.svg",
    title: "Strategy Planner",
    description: "Get directions on positioning, media mix, engagement tactics, and much more."
  },
];

function ProductMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box component="li" sx={{ position: 'relative' }}>
      <Button className="more-app-icon" onClick={toggleMenu}>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          {[3, 13, 23].map((cx) =>
            [3, 13, 23].map((cy) => (
              <circle key={`${cx}-${cy}`} cx={cx} cy={cy} r="3" fill="#007EFC" />
            ))
          )}
        </svg>
      </Button>
      {isOpen && (
        <Box
          component="ul"
          className="submenu products-list-menu"
          sx={{
            position: 'absolute',
            top: '100%',
            right: 0,
            background: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            padding: '16px',
            zIndex: 1,
            minWidth: '280px',
          }}
        >
          {products.map((product, index) => (
            <Box component="li" key={index}>
              <NavLink
                to={product.path}
                className={({ isActive }) =>
                  isActive ? 'active product__menu-block' : 'product__menu-block'
                }
              >
                <Box component="span" className="product__menu-img">
                  <img src={product.icon} alt="" width={26} height={26} />
                </Box>
                <Box component="span" className="product__menu-content">
                  <Typography variant="h6">{product.title}</Typography>
                  <Typography variant="body2">{product.description}</Typography>
                </Box>
              </NavLink>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ProductMenu;
