import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance'; // Axios instance for authenticated requests

// Async action to fetch GA4 accounts and properties
export const fetchAccountsAndProperties = createAsyncThunk(
  'ga4Connect/fetchAccountsAndProperties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.get('/api/ga4/accounts_and_properties/');
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to fetch accounts and properties.';
      return rejectWithValue(errorMessage);
    }
  }
);

// Async action to submit selected properties
export const submitSelectedProperties = createAsyncThunk(
  'ga4Connect/submitSelectedProperties',
  async (selectedProperties, { rejectWithValue }) => {
    try {
      const response = await backendAxiosInstance.post('/api/ga4/connect_properties/', {
        selected_properties: selectedProperties,
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to submit selected properties.';
      return rejectWithValue(errorMessage);
    }
  }
);

const ga4ConnectSlice = createSlice({
  name: 'ga4Connect',
  initialState: {
    accounts: [], // List of GA4 accounts and their properties
    tier: '', // User's subscription tier
    tierLimit: 0, // Maximum properties allowed based on the user's tier
    selectedProperties: [], // Properties selected for connection
    connectedProperties: [], // Properties already connected
    status: 'idle', // API call status: 'idle', 'loading', 'succeeded', 'failed'
    error: null, // Error message for any API or validation issue
    successMessage: null, // Success message after submission
    remainingProperties: 0, // Number of properties the user can still select
  },
  reducers: {
    togglePropertySelection: (state, action) => {
      const propertyId = action.payload;
      const selectedSet = new Set(state.selectedProperties);

      // Prevent toggling already connected properties
      if (state.connectedProperties.includes(propertyId)) {
        state.error = 'This property is already connected.';
        return;
      }

      // Add or remove property from selection
      if (selectedSet.has(propertyId)) {
        selectedSet.delete(propertyId);
      } else {
        if (selectedSet.size >= state.tierLimit) {
          state.error = `You can only select up to ${state.tierLimit} properties.`;
          return;
        }
        selectedSet.add(propertyId);
      }

      state.selectedProperties = Array.from(selectedSet);
      state.error = null; // Clear any previous error
      state.remainingProperties = state.tierLimit - state.selectedProperties.length;
    },
    clearMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch accounts and properties
      .addCase(fetchAccountsAndProperties.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(fetchAccountsAndProperties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { account_list, user_tier, tier_limit } = action.payload;

        state.accounts = account_list || [];
        state.tier = user_tier || '';
        state.tierLimit = tier_limit || 0;

        // Extract connected properties
        state.connectedProperties = account_list.flatMap((account) =>
          account.property_list
            .filter((property) => property.connected)
            .map((property) => property.id)
        );

        state.remainingProperties = state.tierLimit - state.selectedProperties.length;
        state.error = null; // Clear error
      })
      .addCase(fetchAccountsAndProperties.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch accounts and properties.';
      })

      // Submit selected properties
      .addCase(submitSelectedProperties.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(submitSelectedProperties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.successMessage = action.payload?.message || 'Properties connected successfully.';

        // Add newly connected properties
        state.connectedProperties = [
          ...state.connectedProperties,
          ...state.selectedProperties,
        ];

        state.selectedProperties = [];
        state.remainingProperties = state.tierLimit;
        state.error = null;
      })
      .addCase(submitSelectedProperties.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to submit selected properties.';
      });
  },
});

export const { togglePropertySelection, clearMessages } = ga4ConnectSlice.actions;
export default ga4ConnectSlice.reducer;
