import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/style.css'; 
import logo from '../../assets/img/sowhatlabs_logo.svg';


const UserFlowLoginLayout = ({ children }) => {
  return (
    <div className="user-screen">
      <div className="nav">
        <div className="logo">
          <Link to="/select_source">
           <img src={logo} alt="So What Labs Logo" />
          </Link>
        </div>
      </div>
      <div className="content-users">
        {children}
      </div>
    </div>
  );
};

export default UserFlowLoginLayout;


