import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  return (

        <div className="sidebar">
          <ul>
            <li>
              <Link to="/dashboard" className={location.pathname === '/dashboard' ? 'active-tab' : ''}>
                <i className="fas fa-home"></i>
                <span className="nav-title">Overview</span>
                <span className="nav-indicator"></span>
              </Link>
              <ul className="sub-nav">
                <li>
                  <Link to="/dashboard" className={location.pathname === '/dashboard' ? 'active-tab' : ''}>Dashboard</Link>
                </li>
                <li>
                  <Link to="/overview" className={location.pathname === '/overview' ? 'active-tab' : ''}>Overall Trends</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/audience" className={location.pathname === '/audience' ? 'active-tab' : ''}>
                <i className="fas fa-users"></i>
                <span className="nav-title">Audience</span>
                <span className="nav-indicator"></span>
              </Link>
              <ul className="sub-nav">
                <li>
                  <Link to="/audience" className={location.pathname === '/audience' ? 'active-tab' : ''}>Overview</Link>
                </li>
                <li>
                  <Link to="/demographics" className={location.pathname === '/demographics' ? 'active-tab' : ''}>Demographics</Link>
                </li>
                <li>
                  <Link to="/interests" className={location.pathname === '/interests' ? 'active-tab' : ''}>Interests</Link>
                </li>
                <li>
                  <Link to="/geo" className={location.pathname === '/geo' ? 'active-tab' : ''}>Geo</Link>
                </li>
                <li>
                  <Link to="/device" className={location.pathname === '/device' ? 'active-tab' : ''}>Device</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/acquisition" className={location.pathname === '/acquisition' ? 'active-tab' : ''}>
                <i className="fas fa-chart-bar"></i>
                <span className="nav-title">Acquisition</span>
                <span className="nav-indicator"></span>
              </Link>
              <ul className="sub-nav">
                <li>
                  <Link to="/acquisition" className={location.pathname === '/acquisition' ? 'active-tab' : ''}>Overview</Link>
                </li>
                <li>
                  <Link to="/campaign" className={location.pathname === '/campaign' ? 'active-tab' : ''}>Campaigns</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/behaviour" className={location.pathname === '/behaviour' ? 'active-tab' : ''}>
                <i className="fas fa-chart-line"></i>
                <span className="nav-title">Behaviour</span>
              </Link>
            </li>

            <li>
              <Link to="/conversions" className={location.pathname === '/conversions' ? 'active-tab' : ''}>
                <i className="fas fa-shopping-cart"></i>
                <span className="nav-title">Conversions</span>
              </Link>
            </li>

            <li>
              <Link to="/ecommerce" className={location.pathname === '/ecommerce' ? 'active-tab' : ''}>
                <i className="fas fa-credit-card"></i>
                <span className="nav-title">Ecommerce</span>
                <span className="nav-indicator"></span>
              </Link>
              <ul className="sub-nav">
                <li>
                  <Link to="/ecommerce" className={location.pathname === '/ecommerce' ? 'active-tab' : ''}>Overview</Link>
                </li>
                <li>
                  <Link to="/categoryinterest" className={location.pathname === '/categoryinterest' ? 'active-tab' : ''}>Category-Interest</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/data_fetch" className={location.pathname === '/data_fetch' ? 'active-tab' : ''}>
                <i className="fas fa-ad"></i>
                <span className="nav-title">Google Ads</span>
                <span className="nav-indicator"></span>
              </Link>
              <ul className="sub-nav">
                <li>
                  <Link to="/data_fetch" className={location.pathname === '/data_fetch' ? 'active-tab' : ''}>Cost - Revenue</Link>
                </li>
                <li>
                  <Link to="/key-events" className={location.pathname === '/key-events' ? 'active-tab' : ''}>Cost - Key Events</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/cards" className={location.pathname === '/cards' ? 'active-tab' : ''}>
                <i className="fas fa-lightbulb"></i>
                <span className="nav-title">Insights</span>
              </Link>
            </li>

            <li>
              <Link to="/recommendations" className={location.pathname === '/recommendations' ? 'active-tab' : ''}>
                <i className="fas fa-list-alt"></i>
                <span className="nav-title">Recommendations</span>
              </Link>
            </li>
          </ul>
        </div>

  );
};

export default Sidebar;
