import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../features/auth/authSlice';
import UserFlowLoginLayout from '../../components/Layout/UserFlowLoginLayout';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate(); // For navigation after successful login
  const dispatch = useDispatch();
  const { isAuthenticated, error, loading } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(null); // Reset errors before submission
    dispatch(loginUser(formData));
  };

  // Navigate to the select app page if authentication is successful
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/selectapp'); // Only navigate after the component renders
    }
  }, [isAuthenticated, navigate]); // Dependency on isAuthenticated and navigate

  // Handle potential errors coming from the Redux store
  useEffect(() => {
    if (error) {
      setErrors(error);
    }
  }, [error]);

  return (
    <UserFlowLoginLayout>
      <h2 className="text-center">
        Login or <Link to="/signup">Sign Up</Link>
      </h2>
      <form method="post" onSubmit={handleSubmit}>
        <div className="form-inputs mb-16">
          <label htmlFor="email">
            Email:
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <label htmlFor="password">
            Password:
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>

        {errors && (
          <div className="error-messages mb-16">
            {typeof errors === 'object' ? (
              Object.entries(errors).map(([field, messages]) => (
                <div key={field}>
                  <strong>{field}:</strong>{' '}
                  {Array.isArray(messages) ? messages.join(' ') : messages}
                </div>
              ))
            ) : (
              <div>{errors}</div>
            )}
          </div>
        )}

        <button type="submit" className="btn btn--block" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <div className="mt-16">
          <Link to="/forgotpassword">Forgot Password?</Link>
        </div>
      </form>
    </UserFlowLoginLayout>
  );
};

export default LoginPage;
