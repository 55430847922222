import axios from 'axios';
import { store } from '../store/store';
import { logout, refreshAccessToken } from '../features/auth/authSlice';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth?.accessToken; // Retrieve the token from Redux state

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add Authorization header
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh and redirect on 401 errors
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if successful
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      // Prevent retry loops
      if (!originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Attempt to refresh the access token
          const refreshResult = await store.dispatch(refreshAccessToken());

          if (refreshResult.payload && refreshResult.payload.accessToken) {
            const newAccessToken = refreshResult.payload.accessToken;

            // Update the Redux state and request headers with the new token
            store.dispatch({ type: 'auth/setAccessToken', payload: newAccessToken });
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

            // Retry the original request with the new token
            return axiosInstance(originalRequest);
          } else {
            // If refresh failed, redirect to connect-ga4-account
            console.warn('Access token refresh failed. Redirecting to connect-ga4-account.');
            store.dispatch(logout());
            window.location.href = '/connect-ga4-account'; // Redirect to connect-ga4-account page
            return Promise.reject(error);
          }
        } catch (refreshError) {
          console.error('Token refresh error:', refreshError);

          // Redirect to connect-ga4-account on refresh failure
          store.dispatch(logout());
          window.location.href = '/connect-ga4-account'; // Redirect to connect-ga4-account page
          return Promise.reject(refreshError);
        }
      }
    }

    // Redirect to connect-ga4-account if no token is provided
    if (error.response && error.response.status === 403) {
      console.warn('Missing credentials. Redirecting to connect-ga4-account.');
      window.location.href = '/connect-ga4-account'; // Redirect to connect-ga4-account page
    }

    // For all other errors, reject the promise
    console.error('API call error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
