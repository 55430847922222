import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Settings from './Profile';
import AppList from './AppList';
import TokenUsage from './TokenUsage';
import SelectAccountProperty from './SelectAccountProperty';
import logo from './../assets/img/sowhatlabs_logo.svg';

function Navbar() {
  const [tokenUsage, setTokenUsage] = useState({
    usedPercentage: 0,
    remaining: 0,
    total: 0,
  });

  // Fetch token usage data on component mount
  useEffect(() => {
    const fetchTokenCount = async () => {
      try {
        const response = await axios.get('get_token_count/');
        const { token_allowance, fixed_tokens_remaining, tokens_purchased } = response.data;
        const totalTokens = token_allowance + tokens_purchased;
        const usedPercentage = 100 - Math.round((fixed_tokens_remaining * 100) / totalTokens);

        setTokenUsage({
          usedPercentage,
          remaining: fixed_tokens_remaining,
          total: totalTokens,
        });
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };
    fetchTokenCount();
  }, []);

  return (
    <header className="app-header">
      <button className="menu-toggle menu-toggle--sidebar"></button>
      {/* Left Section - Logo */}
      <div className="app-logo">
        <Link to="/">
          <img src={logo} alt="So What Labs Logo" />
        </Link>
      </div>

      {/* Middle Section - Select Account/Property */}            
      <div className="propertyform desktop-view" >
        <SelectAccountProperty />
      </div>
    
  <ul className="navbar">
    <Link to="/connect-properties" className="connect-properties-link">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="connect-icon"
        >
          <path d="M8 1.25V14.75" stroke="#007EFC" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.75 8H1.25" stroke="#007EFC" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span className="button-text">Connect account / Property</span>
      </Link>
    
      {/* Right Section - Tokens, App List, and Settings */}
     
        <li>
          {/* Token Usage */}
          <TokenUsage />
        </li>
        
        <li>
          {/* App List */}
          <AppList />
        </li>

        <li>
          {/* Settings */}
          <Settings />
        </li>
      </ul>
    </header>
  );
}

export default Navbar;
