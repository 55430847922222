// src/features/ga4/categoryInterestSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { fetchCategoryInterestData } from './categoryInterestThunks';

const initialState = {
  chartData: [],
  dimensions: [],
  metrics: [],
  itemCategories: [],
  selectedDimension: null,
  selectedMetric: null,
  selectedItemCategory: null,
  startDate: null,
  endDate: null,
  prevStartDate: null,
  prevEndDate: null,
  currentData: [], // Add currentData to initial state
  loading: false,
  error: null,
};

const categoryInterestSlice = createSlice({
  name: 'categoryInterest',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const {
        dimension,
        metric,
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
        itemCategory,
      } = action.payload;
      if (dimension !== undefined) state.selectedDimension = dimension;
      if (metric !== undefined) state.selectedMetric = metric;
      if (startDate !== undefined) state.startDate = startDate;
      if (endDate !== undefined) state.endDate = endDate;
      if (prevStartDate !== undefined) state.prevStartDate = prevStartDate;
      if (prevEndDate !== undefined) state.prevEndDate = prevEndDate;
      if (itemCategory !== undefined) state.selectedItemCategory = itemCategory;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryInterestData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoryInterestData.fulfilled, (state, action) => {
        const data = action.payload;

        // Process dimensions and metrics
        state.dimensions = data.dimensions.map((dim) => ({
          original: dim.original,
          friendly: dim.friendly,
        }));
        state.metrics = data.metrics.map((metric) => ({
          original: metric.original,
          friendly: metric.friendly,
        }));
        state.itemCategories = data.item_categories || [];
        state.chartData = data.chart_data || [];
        state.currentData = data.current_data || []; // Store currentData
        state.startDate = data.start_date;
        state.endDate = data.end_date;
        state.prevStartDate = data.prev_start_date;
        state.prevEndDate = data.prev_end_date;
        state.loading = false;
      })
      .addCase(fetchCategoryInterestData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilters } = categoryInterestSlice.actions;

export default categoryInterestSlice.reducer;
