import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const BehaviourPage = () => (
  <BaseAnalyticsComponent
    moduleName="behaviour"
    moduleTitle="Behaviour Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default BehaviourPage;
