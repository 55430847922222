import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../assets/img/sowhatlabs_logo.svg';

const SignupPage = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password1: '',
    password2: '',
  });

  const [aiDataConsent, setAiDataConsent] = useState(false);
  const [privacyTermsAgreement, setPrivacyTermsAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure that passwords match
    if (formData.password1 !== formData.password2) {
      setErrorMessage("Passwords don't match.");
      return;
    }

    // Check if both checkboxes are checked
    if (!aiDataConsent || !privacyTermsAgreement) {
      setErrorMessage('You must agree to both the AI Data Consent and Privacy Terms Agreement.');
      return;
    }

    // Build payload with registration data
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password1: formData.password1,
      password2: formData.password2,
      ai_data_consent: aiDataConsent,
      privacy_terms_agreement: privacyTermsAgreement,
    };

    try {
      // Send POST request to your JWT registration endpoint
      const response = await axios.post('https://dev1.sowhatlabs.com/api/account/auth/registration/', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        // On success, save the JWT tokens (access and refresh tokens) to local storage or cookies
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);

        setSuccessMessage('Account created successfully. You are now logged in!');
        setErrorMessage(null);
      }
    } catch (error) {
      // Handle specific error messages from the server
      if (error.response) {
        if (error.response.data.email) {
          setErrorMessage('An account with this email already exists.');
        } else if (error.response.data.non_field_errors) {
          setErrorMessage(error.response.data.non_field_errors[0]);
        } else if (error.response.status === 400) {
          setErrorMessage('Invalid input. Please check your details.');
        } else {
          setErrorMessage('Error creating account. Please try again later.');
        }
      } else {
        setErrorMessage('Error creating account. Please check your internet connection.');
      }
      setSuccessMessage(null);
    }
  };

  return (
    <div className="user-screen">
      <div className="nav">
        <div className="logo">
          <a href="/select_source">
            <img src={logo} alt="So What Labs Logo" />
          </a>
        </div>
      </div>

      <div className="content-users">
        <h2 className="text-center">
          <a href="/login">Login</a> or Sign Up
        </h2>
        <form method="post" onSubmit={handleSubmit}>
          {/* Form inputs */}
          <div className="form-inputs mb-16">
            {/* Email */}
            <p>
              <label htmlFor="id_email">E-mail:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </p>

            <p>
              {/* First Name */}
              <label htmlFor="id_first_name">First Name:</label>
              <input
                type="text"
                className="form-control"
                name="first_name"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </p>

            <p>
              {/* Last Name */}
              <label htmlFor="id_last_name">Last Name:</label>
              <input
                type="text"
                className="form-control"
                name="last_name"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </p>

            <p>
              {/* Password */}
              <label htmlFor="id_password1">Password:</label>
              <input
                type="password"
                className="form-control"
                name="password1"
                placeholder="Password"
                value={formData.password1}
                onChange={handleChange}
                required
              />
            </p>

            <p>
              {/* Confirm Password */}
              <label htmlFor="id_password2">Password (again):</label>
              <input
                type="password"
                className="form-control"
                name="password2"
                placeholder="Confirm Password"
                value={formData.password2}
                onChange={handleChange}
                required
              />
            </p>
          </div>

          {/* AI Data Consent */}
          <div className="mb-16" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="aiDataConsent"
              name="aiDataConsent"
              required
              style={{ marginRight: '10px' }}
              checked={aiDataConsent}
              onChange={() => setAiDataConsent(!aiDataConsent)}
            />
            <label htmlFor="aiDataConsent">
              So What Labs uses external AI models to analyze some of your marketing, ecommerce, or web analytics data.
              By using this app, you consent to your data being shared safely and securely with the AI models.
            </label>
          </div>

          {/* Privacy Terms Agreement */}
          <div className="mb-16" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="privacyTermsAgreement"
              name="privacyTermsAgreement"
              required
              style={{ marginRight: '10px' }}
              checked={privacyTermsAgreement}
              onChange={() => setPrivacyTermsAgreement(!privacyTermsAgreement)}
            />
            <label htmlFor="privacyTermsAgreement">
              I have read and agree to the{' '}
              <a href="https://sowhatlabs.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>.
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn--block">
            Register
          </button>

          {/* Success/Error Messages */}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
