import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors';

// Helper function to format dates to YYYY-MM-DD
const formatDate = (date) => {
  return date instanceof Date ? date.toISOString().split('T')[0] : date;
};

// Async action to fetch all widgets' data at once
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async ({ startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) {
        throw new Error('No property selected. Please select or connect a property.');
      }

      const formattedPropertyId = propertyId.startsWith('properties/')
        ? propertyId
        : `properties/${propertyId}`;

      const params = {
        property_id: formattedPropertyId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      };

      if (isComparisonEnabled) {
        params.prev_start_date = formatDate(prevStartDate);
        params.prev_end_date = formatDate(prevEndDate);
      }

      const response = await backendAxiosInstance.get('/api/ga4/dashboard', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 403 && error.response?.data?.redirect === 'connect_ga4_account') {
        window.location.href = '/connect-ga4-account'; // Redirect immediately
        return rejectWithValue('redirect_to_connect_ga4_account');
      }
      return rejectWithValue(error.response?.data?.message || error.message || 'Failed to fetch dashboard data');
    }
  }
);

// Async action to fetch data for a specific widget
export const fetchWidgetData = createAsyncThunk(
  'dashboard/fetchWidgetData',
  async ({ widget, startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) {
        throw new Error('No property selected. Please select or connect a property.');
      }

      const formattedPropertyId = propertyId.startsWith('properties/')
        ? propertyId
        : `properties/${propertyId}`;

      const params = {
        property_id: formattedPropertyId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        widgets: [widget],
      };

      if (isComparisonEnabled) {
        params.prev_start_date = formatDate(prevStartDate);
        params.prev_end_date = formatDate(prevEndDate);
      }

      const response = await backendAxiosInstance.get('/api/ga4/dashboard', { params });
      return { widget, data: response.data[widget] };
    } catch (error) {
      if (error.response?.status === 403 && error.response?.data?.redirect === 'connect_ga4_account') {
        window.location.href = '/connect-ga4-account'; // Redirect immediately
        return rejectWithValue('redirect_to_connect_ga4_account');
      }
      return rejectWithValue({ widget, error: error.response?.data?.message || error.message || 'Failed to fetch widget data' });
    }
  }
);

const initialState = {
  widgets: {
    totals: { data: null, status: 'idle', error: null },
    chart: { data: null, status: 'idle', error: null },
    table: { data: null, status: 'idle', error: null },
  },
  commonStatus: 'idle',
  commonError: null,
  reportItems: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetWidgetState: (state, action) => {
      const widget = action.payload;
      if (state.widgets[widget]) {
        state.widgets[widget] = { data: null, status: 'idle', error: null };
      }
    },
    resetDashboardState: () => initialState,
    addToReport: (state, action) => {
      const metric = action.payload;
      if (!state.reportItems.some((item) => item.key === metric.key)) {
        state.reportItems.push(metric);
      }
    },
    removeFromReport: (state, action) => {
      state.reportItems = state.reportItems.filter((item) => item.key !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.commonStatus = 'loading';
        state.commonError = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.commonStatus = 'succeeded';
        Object.keys(state.widgets).forEach((widget) => {
          if (action.payload[widget]) {
            state.widgets[widget] = {
              data: action.payload[widget],
              status: 'succeeded',
              error: null,
            };
          }
        });
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        if (action.payload === 'redirect_to_connect_ga4_account') {
          state.commonStatus = 'idle'; // No error state if redirecting
        } else {
          state.commonStatus = 'failed';
          state.commonError = action.payload || 'An error occurred while fetching dashboard data.';
        }
      })

      .addCase(fetchWidgetData.pending, (state, action) => {
        const widget = action.meta.arg.widget;
        if (state.widgets[widget]) {
          state.widgets[widget].status = 'loading';
          state.widgets[widget].error = null;
        }
      })
      .addCase(fetchWidgetData.fulfilled, (state, action) => {
        const { widget, data } = action.payload;
        if (state.widgets[widget]) {
          state.widgets[widget] = {
            data,
            status: 'succeeded',
            error: null,
          };
        }
      })
      .addCase(fetchWidgetData.rejected, (state, action) => {
        const { widget, error } = action.payload || {};
        if (error === 'redirect_to_connect_ga4_account') {
          state.widgets[widget].status = 'idle'; // No error state if redirecting
        } else if (widget && state.widgets[widget]) {
          state.widgets[widget].status = 'failed';
          state.widgets[widget].error = error || 'Failed to fetch widget data.';
        }
      });
  },
});

export const { resetWidgetState, resetDashboardState, addToReport, removeFromReport } = dashboardSlice.actions;
export default dashboardSlice.reducer;
