import { createSlice } from '@reduxjs/toolkit';
import { fetchBaseModuleData } from './baseThunks'; // Import the thunk

const initialState = {
  modules: {}, // Stores data for each module dynamically
  reportItems: [], // Array to track added charts and tables for custom reports
};

const baseSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const {
        moduleName,
        dimension,
        metric,
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
      } = action.payload;
      if (!state.modules[moduleName]) {
        state.modules[moduleName] = {};
      }
      const moduleState = state.modules[moduleName];
      if (dimension) moduleState.selectedDimension = dimension;
      if (metric) moduleState.selectedMetric = metric;
      if (startDate) moduleState.startDate = startDate;
      if (endDate) moduleState.endDate = endDate;
      if (prevStartDate) moduleState.prevStartDate = prevStartDate;
      if (prevEndDate) moduleState.prevEndDate = prevEndDate;
    },
    resetModuleState: (state, action) => {
      const { moduleName } = action.payload;
      if (state.modules[moduleName]) {
        delete state.modules[moduleName];
      }
    },
    addToReport: (state, action) => {
      const { id, type, label, data } = action.payload; // Add `type` for identifying charts or tables
      const isAlreadyAdded = state.reportItems.some((item) => item.id === id);
      if (!isAlreadyAdded) {
        state.reportItems.push({ id, type, label, data });
      }
    },
    removeFromReport: (state, action) => {
      const { id } = action.payload;
      state.reportItems = state.reportItems.filter((item) => item.id !== id);
    },
    resetReport: (state) => {
      state.reportItems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle data fetch request
      .addCase(fetchBaseModuleData.pending, (state, action) => {
        const { moduleName } = action.meta.arg;
        if (!state.modules[moduleName]) {
          state.modules[moduleName] = {};
        }
        state.modules[moduleName].loading = true;
        state.modules[moduleName].error = null;
      })
      // Handle successful data fetch
      .addCase(fetchBaseModuleData.fulfilled, (state, action) => {
        const { moduleName, data } = action.payload;

        // Extract and process dimensions and metrics with user-friendly names
        const processedDimensions = data.dimensions.map((dim) => ({
          original: dim.original,
          friendly: dim.friendly,
        }));

        const processedMetrics = data.metrics.map((metric) => ({
          original: metric.original,
          friendly: metric.friendly,
        }));

        // Update module state
        state.modules[moduleName] = {
          ...state.modules[moduleName],
          startDate: data.start_date,
          endDate: data.end_date,
          prevStartDate: data.prev_start_date,
          prevEndDate: data.prev_end_date,
          dimensions: processedDimensions,
          metrics: processedMetrics,
          chartData: data.chart_data, // Store chart data
          loading: false,
          error: null,
        };
      })
      // Handle rejected data fetch
      .addCase(fetchBaseModuleData.rejected, (state, action) => {
        const { moduleName } = action.meta.arg;
        if (!state.modules[moduleName]) {
          state.modules[moduleName] = {};
        }
        state.modules[moduleName].loading = false;
        state.modules[moduleName].error = action.payload;
      });
  },
});

export const {
  setFilters,
  resetModuleState,
  addToReport,
  removeFromReport,
  resetReport,
} = baseSlice.actions;

export default baseSlice.reducer;
