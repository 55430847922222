import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const InterestsPage = () => (
  <BaseAnalyticsComponent
    moduleName="interests"
    moduleTitle="Interests Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default InterestsPage;
