import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const GeoPage = () => (
  <BaseAnalyticsComponent
    moduleName="geo"
    moduleTitle="Geographical Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default GeoPage;
