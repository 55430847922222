import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../assets/img/sowhatlabs_logo.svg';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send POST request to your password reset endpoint
      const response = await axios.post('https://localhost:8000/account/api/auth/password/reset/', { email }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset email sent successfully. Please check your email.');
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage('Error sending password reset email. Please check the email address provided.');
      setSuccessMessage(null);
    }
  };

  return (
    <div className="user-screen">
      <div className="nav">
        <div className="logo">
          <a href="/select_source">
            <img src={logo} alt="So What Labs Logo" />
          </a>
        </div>
      </div>

      <div className="content-users">
        <h1 className="text-center">Password Reset</h1>

        <p>Forgotten your password? Enter your e-mail address below, and we'll send you an e-mail allowing you to reset it.</p>

        <form method="post" onSubmit={handleSubmit} className="password_reset">
          {/* Email input */}
          <div className="form-inputs mb-16">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Submit button */}
          <input type="submit" className="btn btn--block" value="Reset My Password" />

          {/* Success/Error Messages */}
          {errorMessage && <p style={{ color: 'red', marginTop: '16px' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green', marginTop: '16px' }}>{successMessage}</p>}
        </form>

        <p className="mt-16">Please contact us if you have any trouble resetting your password.</p>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
