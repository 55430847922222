// src/pages/ga4/CampaignPage.js

import React from 'react';
import CampaignComponent from '../../components/ga4/CampaignComponent';

const CampaignPage = () => {
  return (
    <CampaignComponent
      moduleName="campaign"
      title="Campaign Overview"
    />
  );
};

export default CampaignPage;
