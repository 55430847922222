// src/components/DateRangePicker.js
// Import statements
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Switch,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import { setDateRange, toggleComparison } from '../features/date/dateSlice';
import {
  addDays,
  subDays,
  subMonths,
  subYears,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from 'date-fns';

function DateRangePicker({ onDateChange }) {
  const dispatch = useDispatch();
  const { startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled } =
    useSelector((state) => state.dateRange);

  const [currentRange, setCurrentRange] = useState({
    start: startDate ? new Date(startDate) : subDays(new Date(), 29),
    end: endDate ? new Date(endDate) : new Date(),
  });
  const [compareEnabled, setCompareEnabled] = useState(isComparisonEnabled);
  const [previousRange, setPreviousRange] = useState({
    start: prevStartDate ? new Date(prevStartDate) : null,
    end: prevEndDate ? new Date(prevEndDate) : null,
  });

  const [currentPeriodOpen, setCurrentPeriodOpen] = useState(false);
  const [previousPeriodOpen, setPreviousPeriodOpen] = useState(false);

  const handleCurrentPeriodOpen = () => setCurrentPeriodOpen(true);
  const handlePreviousPeriodOpen = () => setPreviousPeriodOpen(true);
  const handleCurrentPeriodClose = () => setCurrentPeriodOpen(false);
  const handlePreviousPeriodClose = () => setPreviousPeriodOpen(false);

  const formatDate = (date) => date.toISOString().split('T')[0];
  const formatDateWithoutWeekday = (date) =>
    date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

  const toggleComparisonHandler = () => {
    const updatedCompareEnabled = !compareEnabled;
    setCompareEnabled(updatedCompareEnabled);
    dispatch(toggleComparison());

    if (updatedCompareEnabled) {
      const daysDifference =
        differenceInDays(currentRange.end, currentRange.start) + 1;
      const newPrevStart = subDays(currentRange.start, daysDifference);
      const newPrevEnd = subDays(currentRange.start, 1);
      setPreviousRange({ start: newPrevStart, end: newPrevEnd });
      updateDateRange(
        currentRange,
        { start: newPrevStart, end: newPrevEnd },
        updatedCompareEnabled
      );
    } else {
      setPreviousRange({ start: null, end: null });
      updateDateRange(currentRange, { start: null, end: null }, updatedCompareEnabled);
    }
  };

  const comparisonPresets = useMemo(() => {
    if (!currentRange.start || !currentRange.end) return [];
    return [
      {
        label: 'Previous Period',
        range: {
          start: subDays(
            currentRange.start,
            differenceInDays(currentRange.end, currentRange.start) + 1
          ),
          end: subDays(currentRange.start, 1),
        },
      },
      {
        label: 'Same Period Last Month',
        range: {
          start: subMonths(currentRange.start, 1),
          end: subMonths(currentRange.end, 1),
        },
      },
      {
        label: 'Same Period Last Year',
        range: {
          start: subYears(currentRange.start, 1),
          end: subYears(currentRange.end, 1),
        },
      },
    ];
  }, [currentRange]);

  const renderPresets = (presets, setRange, closeDialog) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        p: 2,
        width: '200px',
      }}
    >
      {presets.map((preset) => (
        <Button
          key={preset.label}
          onClick={() => {
            setRange(preset.range);
            closeDialog();
            handleApply();
          }}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            padding: '4px 0',
            minWidth: '100%',
            lineHeight: 2,
            border: 'none',
          }}
        >
          {preset.label}
        </Button>
      ))}
    </Box>
  );

  const currentPeriodPresets = [
    {
      label: 'Today',
      range: { start: new Date(), end: new Date() },
    },
    {
      label: 'Yesterday',
      range: {
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1),
      },
    },
    {
      label: 'Last 7 days',
      range: { start: addDays(new Date(), -6), end: new Date() },
    },
    {
      label: 'Last 30 days',
      range: { start: addDays(new Date(), -29), end: new Date() },
    },
    {
      label: 'This Month',
      range: { start: startOfMonth(new Date()), end: new Date() },
    },
    {
      label: 'Last Month',
      range: {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      },
    },
    {
      label: 'This Year',
      range: { start: startOfYear(new Date()), end: new Date() },
    },
    {
      label: 'Last Year',
      range: {
        start: startOfYear(subYears(new Date(), 1)),
        end: endOfYear(subYears(new Date(), 1)),
      },
    },
  ];

  const handleApply = () => {
    updateDateRange(currentRange, previousRange, compareEnabled);
  };

  const handleCurrentDateChange = (key) => (newValue) => {
    setCurrentRange((prev) => ({ ...prev, [key]: newValue }));
  };

  const handlePreviousDateChange = (key) => (newValue) => {
    setPreviousRange((prev) => ({ ...prev, [key]: newValue }));
  };

  useEffect(() => {
    if (compareEnabled && currentRange.start && currentRange.end) {
      const daysDifference =
        differenceInDays(currentRange.end, currentRange.start) + 1;
      const newPrevStart = subDays(currentRange.start, daysDifference);
      const newPrevEnd = subDays(currentRange.start, 1);
      setPreviousRange({ start: newPrevStart, end: newPrevEnd });
    }
  }, [currentRange, compareEnabled]);

  useEffect(() => {
    if (!startDate && !endDate) {
      handleApply();
    }
  }, []);

  const updateDateRange = (currentRange, previousRange, compareEnabled) => {
    const datePayload = {
      startDate: currentRange.start ? formatDate(currentRange.start) : null,
      endDate: currentRange.end ? formatDate(currentRange.end) : null,
      prevStartDate:
        compareEnabled && previousRange.start
          ? formatDate(previousRange.start)
          : null,
      prevEndDate:
        compareEnabled && previousRange.end
          ? formatDate(previousRange.end)
          : null,
      isComparisonEnabled: compareEnabled,
    };
    dispatch(setDateRange(datePayload));
    if (onDateChange) onDateChange(datePayload);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={handleCurrentPeriodOpen}
          sx={{ textTransform: 'none' }}
        >
          {currentRange.start && currentRange.end
            ? `${formatDateWithoutWeekday(
                currentRange.start
              )} - ${formatDateWithoutWeekday(currentRange.end)}`
            : 'Select Date Range'}
        </Button>

        {compareEnabled && previousRange.start && previousRange.end && (
          <Button
            variant="outlined"
            onClick={handlePreviousPeriodOpen}
            sx={{ textTransform: 'none' }}
          >
            {`${formatDateWithoutWeekday(
              previousRange.start
            )} - ${formatDateWithoutWeekday(previousRange.end)}`}
          </Button>
        )}
      </Box>

      <FormControlLabel
        control={
          <Switch
            checked={compareEnabled}
            onChange={toggleComparisonHandler}
            color="primary"
          />
        }
        label="Enable Comparison Period"
      />

      {/* Current Period Date Pickers */}
      <Dialog
        open={currentPeriodOpen}
        onClose={handleCurrentPeriodClose}
        maxWidth="sm"
      >
        <DialogContent>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enLocale}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderPresets(
                currentPeriodPresets,
                (range) => {
                  setCurrentRange(range);
                  handleCurrentPeriodClose();
                  handleApply();
                },
                handleCurrentPeriodClose
              )}
              <DatePicker
                label="Start Date"
                value={currentRange.start}
                onChange={handleCurrentDateChange('start')}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={currentRange.end}
                onChange={handleCurrentDateChange('end')}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                variant="contained"
                onClick={() => {
                  handleCurrentPeriodClose();
                  handleApply();
                }}
              >
                Apply
              </Button>
            </Box>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>

      {/* Previous Period Date Pickers */}
      {compareEnabled && previousRange.start && previousRange.end && (
        <Dialog
          open={previousPeriodOpen}
          onClose={handlePreviousPeriodClose}
          maxWidth="sm"
        >
          <DialogContent>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enLocale}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {renderPresets(
                  comparisonPresets,
                  (range) => {
                    setPreviousRange(range);
                    handlePreviousPeriodClose();
                    handleApply();
                  },
                  handlePreviousPeriodClose
                )}
                <DatePicker
                  label="Previous Start Date"
                  value={previousRange.start}
                  onChange={handlePreviousDateChange('start')}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="Previous End Date"
                  value={previousRange.end}
                  onChange={handlePreviousDateChange('end')}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    handlePreviousPeriodClose();
                    handleApply();
                  }}
                >
                  Apply
                </Button>
              </Box>
            </LocalizationProvider>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default React.memo(DateRangePicker);
