import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors'; // Import the selector

// Helper function for error handling
const handleError = (error) => error.response?.data?.detail || error.message || 'An error occurred';

// Utility function to format property ID
const formatPropertyId = (propertyId) => {
  if (propertyId.startsWith('properties/')) {
    return propertyId.replace('properties/', '');
  }
  return propertyId;
};

// Utility function for API calls
const fetchData = async (url, params, rejectWithValue) => {
  try {
    const response = await backendAxiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
};

// Async Thunks

export const fetchConversionsTotal = createAsyncThunk(
  'conversions/fetchTotal',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    const state = getState();
    const propertyId = selectPropertyId(state);
    if (!propertyId) return rejectWithValue('Property ID is not set.');

    const params = {
      property_id: formatPropertyId(propertyId),
      start_date: startDate,
      end_date: endDate,
      prev_start_date: prevStartDate,
      prev_end_date: prevEndDate,
    };

    return fetchData('/api/ga4/conversions-totals/', params, rejectWithValue);
  }
);

export const fetchConversionsDaily = createAsyncThunk(
  'conversions/fetchDaily',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    const state = getState();
    const propertyId = selectPropertyId(state);
    if (!propertyId) return rejectWithValue('Property ID is not set.');

    const params = {
      property_id: formatPropertyId(propertyId),
      start_date: startDate,
      end_date: endDate,
      prev_start_date: prevStartDate,
      prev_end_date: prevEndDate,
    };

    return fetchData('/api/ga4/conversions-daily/', params, rejectWithValue);
  }
);

export const fetchConversionsWeekly = createAsyncThunk(
  'conversions/fetchWeekly',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    const state = getState();
    const propertyId = selectPropertyId(state);
    if (!propertyId) return rejectWithValue('Property ID is not set.');

    const params = {
      property_id: formatPropertyId(propertyId),
      start_date: startDate,
      end_date: endDate,
      prev_start_date: prevStartDate,
      prev_end_date: prevEndDate,
    };

    return fetchData('/api/ga4/conversions-weekly/', params, rejectWithValue);
  }
);

export const fetchConversionsMonthly = createAsyncThunk(
  'conversions/fetchMonthly',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    const state = getState();
    const propertyId = selectPropertyId(state);
    if (!propertyId) return rejectWithValue('Property ID is not set.');

    const params = {
      property_id: formatPropertyId(propertyId),
      start_date: startDate,
      end_date: endDate,
      prev_start_date: prevStartDate,
      prev_end_date: prevEndDate,
    };

    return fetchData('/api/ga4/conversions-monthly/', params, rejectWithValue);
  }
);

// Slice definition
const conversionsSlice = createSlice({
  name: 'conversions',
  initialState: {
    totals: { data: null, loading: false, error: null },
    dailyData: { data: null, loading: false, error: null },
    weeklyData: { data: null, loading: false, error: null },
    monthlyData: { data: null, loading: false, error: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    // Totals
    builder
      .addCase(fetchConversionsTotal.pending, (state) => {
        state.totals.loading = true;
        state.totals.error = null;
      })
      .addCase(fetchConversionsTotal.fulfilled, (state, action) => {
        state.totals.loading = false;
        state.totals.data = action.payload;
      })
      .addCase(fetchConversionsTotal.rejected, (state, action) => {
        state.totals.loading = false;
        state.totals.error = action.payload;
      });

    // Daily
    builder
      .addCase(fetchConversionsDaily.pending, (state) => {
        state.dailyData.loading = true;
        state.dailyData.error = null;
      })
      .addCase(fetchConversionsDaily.fulfilled, (state, action) => {
        state.dailyData.loading = false;
        state.dailyData.data = action.payload;
      })
      .addCase(fetchConversionsDaily.rejected, (state, action) => {
        state.dailyData.loading = false;
        state.dailyData.error = action.payload;
      });

    // Weekly
    builder
      .addCase(fetchConversionsWeekly.pending, (state) => {
        state.weeklyData.loading = true;
        state.weeklyData.error = null;
      })
      .addCase(fetchConversionsWeekly.fulfilled, (state, action) => {
        state.weeklyData.loading = false;
        state.weeklyData.data = action.payload;
      })
      .addCase(fetchConversionsWeekly.rejected, (state, action) => {
        state.weeklyData.loading = false;
        state.weeklyData.error = action.payload;
      });

    // Monthly
    builder
      .addCase(fetchConversionsMonthly.pending, (state) => {
        state.monthlyData.loading = true;
        state.monthlyData.error = null;
      })
      .addCase(fetchConversionsMonthly.fulfilled, (state, action) => {
        state.monthlyData.loading = false;
        state.monthlyData.data = action.payload;
      })
      .addCase(fetchConversionsMonthly.rejected, (state, action) => {
        state.monthlyData.loading = false;
        state.monthlyData.error = action.payload;
      });
  },
});

export default conversionsSlice.reducer;
