
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors';

// Utility function to handle errors
const handleError = (error) =>
  error.response?.data || error.message || 'An error occurred';

// Utility function to ensure `property_id` format
const formatPropertyId = (propertyId) => {
  if (!propertyId.startsWith('properties/')) {
    return `properties/${propertyId}`;
  }
  return propertyId;
};

// Async thunk to fetch demographics data
export const fetchDemographicsData = createAsyncThunk(
  'demographics/fetchData',
  async (
    { startDate, endDate, prevStartDate, prevEndDate },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');
      const formattedPropertyId = formatPropertyId(propertyId);

      const response = await backendAxiosInstance.get('/api/ga4/demographics/', {
        params: {
          property_id: formattedPropertyId,
          start_date: startDate,
          end_date: endDate,
          prev_start_date: prevStartDate,
          prev_end_date: prevEndDate,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Slice definition
const demographicsSlice = createSlice({
  name: 'demographics',
  initialState: {
    currentData: [], // Aggregated current period data for all dimensions and metrics
    previousData: [], // Aggregated previous period data for all dimensions and metrics
    chartData: [], // Data for chart visualization
    percentDifferences: {}, // Percent differences between periods
    dimensions: [], // Available dimensions for filtering
    metrics: [], // Available metrics for analysis
    selectedDimension: '', // Default dimension for analysis
    selectedMetric: '', // Default metric for analysis
    startDate: null, // Current period start date
    endDate: null, // Current period end date
    prevStartDate: null, // Previous period start date
    prevEndDate: null, // Previous period end date
    loading: false, // Loading state
    error: null, // Error state
  },
  reducers: {
    setFilters: (state, action) => {
      const { dimension, metric, startDate, endDate, prevStartDate, prevEndDate } =
        action.payload;
      if (dimension) state.selectedDimension = dimension;
      if (metric) state.selectedMetric = metric;
      if (startDate) state.startDate = startDate;
      if (endDate) state.endDate = endDate;
      if (prevStartDate) state.prevStartDate = prevStartDate;
      if (prevEndDate) state.prevEndDate = prevEndDate;
    },
    resetDemographicsState: (state) => {
      state.currentData = [];
      state.previousData = [];
      state.chartData = [];
      state.percentDifferences = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle data fetch request
      .addCase(fetchDemographicsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle successful data fetch
      .addCase(fetchDemographicsData.fulfilled, (state, action) => {
        const {
          start_date,
          end_date,
          prev_start_date,
          prev_end_date,
          dimensions,
          metrics,
          chart_data,
          current_data,
          previous_data,
          percent_differences,
        } = action.payload;

        state.loading = false;
        state.startDate = start_date;
        state.endDate = end_date;
        state.prevStartDate = prev_start_date;
        state.prevEndDate = prev_end_date;
        state.dimensions = dimensions;
        state.metrics = metrics;
        state.chartData = chart_data;
        state.currentData = current_data;
        state.previousData = previous_data;
        state.percentDifferences = percent_differences || {};
      })
      // Handle rejected data fetch
      .addCase(fetchDemographicsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilters, resetDemographicsState } = demographicsSlice.actions;

export default demographicsSlice.reducer;