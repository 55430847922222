import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const DemographicsPage = () => (
  <BaseAnalyticsComponent
    moduleName="demographics"
    moduleTitle="Demographics Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default DemographicsPage;
