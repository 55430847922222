import { createSlice } from '@reduxjs/toolkit';

// Initial state with dates as null to indicate no selection and comparison off by default
const initialState = {
  startDate: null,      // ISO string (e.g., "2024-10-07T17:56:35.000Z") or null
  endDate: null,        // ISO string or null
  prevStartDate: null,  // ISO string or null
  prevEndDate: null,    // ISO string or null
  isComparisonEnabled: false, // Flag to enable or disable comparison
};

// Create the dateRange slice
const dateSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    /**
     * Sets the current and previous date ranges.
     * Dates should be provided as ISO strings to ensure serializability.
     *
     * @param {Object} state - The current state.
     * @param {Object} action - The action containing the date ranges.
     * @param {string|null} action.payload.startDate - The start date as an ISO string.
     * @param {string|null} action.payload.endDate - The end date as an ISO string.
     * @param {string|null} action.payload.prevStartDate - The previous start date as an ISO string.
     * @param {string|null} action.payload.prevEndDate - The previous end date as an ISO string.
     * @param {boolean} action.payload.isComparisonEnabled - Whether to enable comparison with previous period.
     */
    setDateRange: (state, action) => {
      state.startDate = action.payload.startDate || null;
      state.endDate = action.payload.endDate || null;
      state.prevStartDate = action.payload.isComparisonEnabled ? action.payload.prevStartDate || null : null;
      state.prevEndDate = action.payload.isComparisonEnabled ? action.payload.prevEndDate || null : null;
      state.isComparisonEnabled = action.payload.isComparisonEnabled || false;
    },

    /**
     * Clears all date ranges, resetting them to null and disabling comparison.
     *
     * @param {Object} state - The current state.
     */
    clearDateRange: (state) => {
      state.startDate = null;
      state.endDate = null;
      state.prevStartDate = null;
      state.prevEndDate = null;
      state.isComparisonEnabled = false;
    },

    /**
     * Toggles the isComparisonEnabled flag.
     * If disabling comparison, also clears the previous date range.
     *
     * @param {Object} state - The current state.
     */
    toggleComparison: (state) => {
      state.isComparisonEnabled = !state.isComparisonEnabled;
      if (!state.isComparisonEnabled) {
        state.prevStartDate = null;
        state.prevEndDate = null;
      }
    },
  },
});

// Export the actions for use in components
export const { setDateRange, clearDateRange, toggleComparison } = dateSlice.actions;

// Export the reducer to be included in the store
export default dateSlice.reducer;
