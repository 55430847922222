// src/store/selectors.js

export const selectPropertyId = (state) => {
    const selectedProperties = state.accountProperty?.selectedProperties;

    console.log("Selector - Selected Properties:", selectedProperties); // Debugging

    if (!Array.isArray(selectedProperties) || selectedProperties.length === 0) {
        console.warn("Selector - No selected properties found.");
        return null;
    }

    // Assuming selectedProperties is an array of strings, we use the first item directly
    const propertyId = selectedProperties[0];

    console.log("Selector - Extracted Property ID:", propertyId); // Debugging

    // Ensure the propertyId has the "properties/" prefix if not already present
    return propertyId.startsWith("properties/") ? propertyId : `properties/${propertyId}`;
};
