import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConversionsTotals } from '../../features/ga4/conversionsTotalSlice';
import {
  fetchConversionsDailyData,
  fetchConversionsWeeklyData,
  fetchConversionsMonthlyData,
  clearData,
} from '../../features/ga4/conversionsChartSlice';
import DateRangePicker from '../../components/DateRangePicker';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import {
  Skeleton,
  Grid,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatNumber } from '../../utils/numberUtils';
import { selectPropertyId } from '../../store/selectors';
import { DataGrid } from '@mui/x-data-grid';

const ConversionsPage = () => {
  const dispatch = useDispatch();
  const { conversionsData, status, error } = useSelector((state) => state.conversionsTotal);
  const { currentPeriodData, previousPeriodData, status: chartStatus } = useSelector(
    (state) => state.conversionsChart
  );
  const propertyId = useSelector(selectPropertyId);
  const dateRange = useSelector((state) => state.dateRange);

  const [dates, setDates] = useState({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    prevStartDate: dateRange.prevStartDate,
    prevEndDate: dateRange.prevEndDate,
    isComparisonEnabled: dateRange.isComparisonEnabled,
  });
  const [activeTab, setActiveTab] = useState('daily');
  const [selectedEvent, setSelectedEvent] = useState('All');
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const metrics = [
    { label: 'Conversions', key: 'conversions', type: 'integer' }, 
    { label: 'Purchase Conversions', key: 'conversions:purchase', type: 'integer' },
    { label: 'Total Revenue', key: 'totalRevenue', type: 'currency' },
    { label: 'Conversion Rate', key: 'purchaserConversionRate', type: 'percentage' },
  ];

  const fetchData = useCallback(async () => {
    if (!propertyId || !dates.startDate || !dates.endDate) return;
    try {
      await dispatch(
        fetchConversionsTotals({
          startDate: dates.startDate,
          endDate: dates.endDate,
          prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
          prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
        })
      );

      const actionPayload = {
        propertyId,
        startDate: dates.startDate,
        endDate: dates.endDate,
        prevStartDate: dates.isComparisonEnabled ? dates.prevStartDate : null,
        prevEndDate: dates.isComparisonEnabled ? dates.prevEndDate : null,
        isComparisonEnabled: dates.isComparisonEnabled,
      };

      if (activeTab === 'daily') {
        await dispatch(fetchConversionsDailyData(actionPayload));
      } else if (activeTab === 'weekly') {
        await dispatch(fetchConversionsWeeklyData(actionPayload));
      } else if (activeTab === 'monthly') {
        await dispatch(fetchConversionsMonthlyData(actionPayload));
      }
    } catch (error) {
      console.error('Failed to fetch conversions data:', error);
    }
  }, [dispatch, propertyId, dates, activeTab]);

  useEffect(() => {
    dispatch(clearData());
    fetchData();
  }, [fetchData, activeTab, dispatch]);

  const handleDateChange = ({ startDate, endDate, prevStartDate, prevEndDate, isComparisonEnabled }) => {
    setDates({
      startDate: startDate || null,
      endDate: endDate || null,
      prevStartDate: isComparisonEnabled ? prevStartDate : null,
      prevEndDate: isComparisonEnabled ? prevEndDate : null,
      isComparisonEnabled,
    });
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const getEventNames = () => {
    const allEventNames = currentPeriodData?.map((item) => item.eventName) || [];
    return ['All', ...new Set(allEventNames)];
  };

  // Function to render loading skeletons
  const renderSkeletons = () => {
    const skeletons = Array.from(new Array(8)).map((_, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <div className="card">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="rectangular" height={60} />
        </div>
      </Grid>
    ));
    return <Grid container spacing={2}>{skeletons}</Grid>;
  };

  // Function to render metrics
  const renderMetrics = () => {
    if (!conversionsData || !conversionsData.totals || !conversionsData.totals.current) {
      return <div>No data available.</div>;
    }

    return (
      <Grid container spacing={2}>
        {metrics.map((metric, index) => {
          const current = conversionsData?.totals?.current?.[metric.key] || 0;
          const previous = conversionsData?.totals?.previous?.[metric.key] || 0;
          const diff = conversionsData?.percent_differences?.[metric.key] || 0;
          const diffClass = diff > 0 ? 'green-arrow' : diff < 0 ? 'red-arrow' : '';

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <div className="card">
                <h3>{metric.label}</h3>
                <p>{formatNumber(current, metric.type)}</p>
                {dates.isComparisonEnabled && (
                  <small>
                    ({formatNumber(previous, metric.type)})
                    {previous !== 0 && (
                      <span className={diffClass}>
                        {diff > 0 ? '↑' : diff < 0 ? '↓' : ''}{' '}
                        {Math.abs(diff).toFixed(2)}%
                      </span>
                    )}
                  </small>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const processData = (data, timeDimension) => {
    const aggregatedData = {};

    data.forEach((item) => {
      const date = item[timeDimension];
      const eventName = item.eventName;
      const eventCount = parseInt(item.eventCount, 10) || 0;

      if (selectedEvent === 'All' || selectedEvent === eventName) {
        if (aggregatedData[date]) {
          aggregatedData[date] += eventCount;
        } else {
          aggregatedData[date] = eventCount;
        }
      }
    });

    return Object.entries(aggregatedData).map(([date, value]) => ({ date, value }));
  };

  const renderTabContent = () => {
    if (!currentPeriodData || currentPeriodData.length === 0) {
      return <div>No data available for the selected period.</div>;
    }

    const timeDimension = activeTab === 'daily' ? 'date' : activeTab === 'weekly' ? 'yearWeek' : 'yearMonth';

    const currentDataProcessed = processData(currentPeriodData, timeDimension);
    const previousDataProcessed = processData(previousPeriodData || [], timeDimension);

    const mergedData = currentDataProcessed.map((dataPoint) => {
      const previousDataPoint = previousDataProcessed.find((item) => item.date === dataPoint.date);
      return {
        date: dataPoint.date,
        currentValue: dataPoint.value,
        previousValue: previousDataPoint ? previousDataPoint.value : 0,
      };
    });

    const tableData = mergedData.map((item, index) => ({
      id: index,
      date: item.date,
      currentValue: item.currentValue,
      previousValue: item.previousValue,
    }));

    const columns = [
      { field: 'date', headerName: 'Date', width: 150 },
      { field: 'currentValue', headerName: 'Current Period', width: 150 },
    ];

    if (dates.isComparisonEnabled) {
      columns.push({ field: 'previousValue', headerName: 'Previous Period', width: 150 });
    }

    return (
      <div>
        <h3>Conversions Over Time ({selectedEvent})</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={mergedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="currentValue" name="Current Period" stroke="#8884d8" />
            {dates.isComparisonEnabled && (
              <Line type="monotone" dataKey="previousValue" name="Previous Period" stroke="#82ca9d" />
            )}
          </LineChart>
        </ResponsiveContainer>
        <div style={{ height: 400, width: '100%', marginTop: 20 }}>
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="main">
        <Sidebar />
        <Navbar />
        <div className="content">
          <div className="title-filter">
            <h1>Conversions Overview</h1>
            <div className="date-pickers-block">
              <DateRangePicker onDateChange={handleDateChange} />
            </div>
            <FormControl style={{ width: 200, marginLeft: 20 }}>
              <InputLabel>Event Name</InputLabel>
              <Select value={selectedEvent} onChange={handleEventChange}>
                {getEventNames().map((eventName) => (
                  <MenuItem key={eventName} value={eventName}>
                    {eventName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {error && (
            <div className="error-message">
              {typeof error === 'string' ? error : error.detail || 'An unexpected error occurred.'}
            </div>
          )}
          {status === 'loading' || chartStatus === 'loading' ? renderSkeletons() : renderMetrics()}

          <div className="row">
            <div className="col-7 col-lg-12 mb-lg-20">
              <div className="chart-wrap">
                <div className="overview_tab">
                  <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                    <Tab label="Daily" value="daily" />
                    <Tab label="Weekly" value="weekly" />
                    <Tab label="Monthly" value="monthly" />
                  </Tabs>

                  {status === 'loading' || chartStatus === 'loading' ? (
                    renderSkeletons()
                  ) : (
                    renderTabContent()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  );
};

export default ConversionsPage;
