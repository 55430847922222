// SelectAccountPropertyPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import SelectAccountProperty from '../../components/SelectAccountProperty';

const SelectAccountPropertyPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionValid, setIsSelectionValid] = useState(false); // Track if a valid selection is made

  useEffect(() => {
    // Check if there is a persisted selection to enable the button initially
    const persistedSelection = localStorage.getItem('selectedAccountProperty');
    setIsSelectionValid(!!persistedSelection);
    console.log("Persisted selection:", persistedSelection);
  }, []);

  const handleSelectionChange = (accountId, propertyId) => {
    if (accountId && propertyId) {
      setIsSelectionValid(true); // Enable the button if selection is valid
      console.log("Selection is valid:", accountId, propertyId);
    } else {
      setIsSelectionValid(false); // Disable the button if selection is invalid
      console.warn('Invalid account or property selection.');
      alert('Please select a valid account and property pair.');
    }
  };

  const handleContinue = () => {
    if (isSelectionValid) {  // Check if selection is valid before continuing
      setIsLoading(true);
      navigate('/dashboard', { replace: true });
      setIsLoading(false);
    }
  };

  return (
    <div className="user-screen">
      <div className="content-users small">
        <h2>Select Account and Property for Analysis</h2>
        <SelectAccountProperty onSelectionChange={handleSelectionChange} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={!isSelectionValid || isLoading} // Disable if no selection or loading
          sx={{ mt: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Continue to Dashboard'}
        </Button>
      </div>
    </div>
  );
};

export default SelectAccountPropertyPage;
