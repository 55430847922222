import React from 'react';
import BaseAnalyticsComponent from '../../components/ga4/BaseAnalyticsComponent';
import { fetchBaseModuleData } from '../../features/ga4/baseThunks';

const EcommercePage = () => (
  <BaseAnalyticsComponent
    moduleName="ecommerce"
    moduleTitle="Ecommerce Overview"
    fetchDataAction={fetchBaseModuleData}
  />
);

export default EcommercePage;
