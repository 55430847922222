// src/features/ga4/categoryInterestThunks.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors';

export const fetchCategoryInterestData = createAsyncThunk(
  'categoryInterest/fetchData',
  async (
    { startDate, endDate, prevStartDate, prevEndDate, itemCategory },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) throw new Error('Property ID is not set.');
      const formattedPropertyId = propertyId.startsWith('properties/')
        ? propertyId
        : `properties/${propertyId}`;

      const response = await backendAxiosInstance.get(`/api/ga4/categoryinterest/`, {
        params: {
          property_id: formattedPropertyId,
          start_date: startDate,
          end_date: endDate,
          prev_start_date: prevStartDate,
          prev_end_date: prevEndDate,
          item_category: itemCategory,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message || 'An error occurred');
    }
  }
);
