import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';
import { selectPropertyId } from '../../store/selectors';

// Helper function to format dates to YYYY-MM-DD
const formatDate = (date) => {
  return date instanceof Date ? date.toISOString().split('T')[0] : date;
};

// Async action to fetch conversions totals
export const fetchConversionsTotals = createAsyncThunk(
  'conversionsTotal/fetchConversionsTotals',
  async ({ startDate, endDate, prevStartDate, prevEndDate }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const propertyId = selectPropertyId(state);

      if (!propertyId) {
        console.warn('Property ID is not set. Cannot fetch conversions totals.');
        throw new Error('Property ID is not set.');
      }

      // Ensure propertyId has the correct format with "properties/" prefix
      const formattedPropertyId = propertyId.startsWith("properties/")
        ? propertyId
        : `properties/${propertyId}`;

      // Logging to confirm parameters before the request
      console.log("Conversions Total API request parameters:", {
        property_id: formattedPropertyId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        prev_start_date: formatDate(prevStartDate),
        prev_end_date: formatDate(prevEndDate),
      });

      // Fetch the conversions totals from the API
      const response = await backendAxiosInstance.get('/api/ga4/conversions-total', {
        params: {
          property_id: formattedPropertyId,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          prev_start_date: formatDate(prevStartDate),
          prev_end_date: formatDate(prevEndDate),
        },
      });

      // Log success response data for debugging
      console.log("Conversions totals fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching conversions totals:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message || 'Failed to fetch conversions totals');
    }
  }
);

// Initial state for conversions totals
const initialState = {
  conversionsData: null,
  status: 'idle', // Possible values: 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

// Create the slice
const conversionsTotalSlice = createSlice({
  name: 'conversionsTotal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversionsTotals.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        console.log('Fetching conversions totals...');
      })
      .addCase(fetchConversionsTotals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.conversionsData = action.payload;
        console.log('Conversions totals fetch succeeded.');
      })
      .addCase(fetchConversionsTotals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error('Conversions totals fetch failed:', action.payload);
      });
  },
});

// Export the async action and the reducer
export default conversionsTotalSlice.reducer;
