// src/features/ga4/campaignSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { fetchCampaignData } from './campaignThunks';

const initialState = {
  chartData: [],
  dimensions: [],
  metrics: [],
  sourceMediumValues: [], // For the dropdown filter
  selectedDimension: null,
  selectedMetric: null,
  selectedSourceMedium: null, // Source/Medium selected in the dropdown
  startDate: null,
  endDate: null,
  prevStartDate: null,
  prevEndDate: null,
  currentData: [], // For table
  loading: false,
  error: null,
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const {
        dimension,
        metric,
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
        sourceMedium,
      } = action.payload;
      if (dimension !== undefined) state.selectedDimension = dimension;
      if (metric !== undefined) state.selectedMetric = metric;
      if (startDate !== undefined) state.startDate = startDate;
      if (endDate !== undefined) state.endDate = endDate;
      if (prevStartDate !== undefined) state.prevStartDate = prevStartDate;
      if (prevEndDate !== undefined) state.prevEndDate = prevEndDate;
      if (sourceMedium !== undefined) state.selectedSourceMedium = sourceMedium;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignData.fulfilled, (state, action) => {
        const data = action.payload;

        // Process dimensions and metrics
        state.dimensions = data.dimensions.map((dim) => ({
          original: dim.original,
          friendly: dim.friendly,
        }));
        state.metrics = data.metrics.map((metric) => ({
          original: metric.original,
          friendly: metric.friendly,
        }));
        state.sourceMediumValues = data.source_medium_values || []; // Populate source/medium dropdown
        state.chartData = data.chart_data || [];
        state.currentData = data.current_data || []; // Store current table data
        state.startDate = data.start_date;
        state.endDate = data.end_date;
        state.prevStartDate = data.prev_start_date;
        state.prevEndDate = data.prev_end_date;
        state.loading = false;
      })
      .addCase(fetchCampaignData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilters } = campaignSlice.actions;

export default campaignSlice.reducer;
