// src/features/ga4/audienceChartSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAxiosInstance from '../../services/backendAxiosInstance';

// Async thunks for fetching data
export const fetchAudienceDailyData = createAsyncThunk(
  'audienceChart/fetchAudienceDailyData',
  async (
    { propertyId, startDate, endDate, prevStartDate, prevEndDate, filterOrganic, isComparisonEnabled },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        property_id: propertyId,
        start_date: startDate,
        end_date: endDate,
        filter_organic: filterOrganic,
      };

      // Add previous date range to params only if comparison is enabled
      if (isComparisonEnabled) {
        params.prev_start_date = prevStartDate;
        params.prev_end_date = prevEndDate;
      }

      // Logging for debugging
      console.log('Fetching daily data with params:', params);

      const response = await backendAxiosInstance.get('/api/ga4/audience/daily', {
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching daily data:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to fetch daily data');
    }
  }
);

export const fetchAudienceWeeklyData = createAsyncThunk(
  'audienceChart/fetchAudienceWeeklyData',
  async (
    { propertyId, startDate, endDate, prevStartDate, prevEndDate, filterOrganic, isComparisonEnabled },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        property_id: propertyId,
        start_date: startDate,
        end_date: endDate,
        filter_organic: filterOrganic,
      };

      // Add previous date range to params only if comparison is enabled
      if (isComparisonEnabled) {
        params.prev_start_date = prevStartDate;
        params.prev_end_date = prevEndDate;
      }

      // Logging for debugging
      console.log('Fetching weekly data with params:', params);

      const response = await backendAxiosInstance.get('/api/ga4/audience/weekly', {
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching weekly data:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to fetch weekly data');
    }
  }
);

export const fetchAudienceMonthlyData = createAsyncThunk(
  'audienceChart/fetchAudienceMonthlyData',
  async (
    { propertyId, startDate, endDate, prevStartDate, prevEndDate, filterOrganic, isComparisonEnabled },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        property_id: propertyId,
        start_date: startDate,
        end_date: endDate,
        filter_organic: filterOrganic,
      };

      // Add previous date range to params only if comparison is enabled
      if (isComparisonEnabled) {
        params.prev_start_date = prevStartDate;
        params.prev_end_date = prevEndDate;
      }

      // Logging for debugging
      console.log('Fetching monthly data with params:', params);

      const response = await backendAxiosInstance.get('/api/ga4/audience/monthly', {
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching monthly data:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Failed to fetch monthly data');
    }
  }
);

const audienceChartSlice = createSlice({
  name: 'audienceChart',
  initialState: {
    currentPeriodData: null,
    previousPeriodData: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    clearData(state) {
      // Clear both current and previous period data
      state.currentPeriodData = [];
      state.previousPeriodData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Daily data
      .addCase(fetchAudienceDailyData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAudienceDailyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentPeriodData = action.payload.current_period;
        state.previousPeriodData = action.payload.previous_period || [];
      })
      .addCase(fetchAudienceDailyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Weekly data
      .addCase(fetchAudienceWeeklyData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAudienceWeeklyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentPeriodData = action.payload.current_period;
        state.previousPeriodData = action.payload.previous_period || [];
      })
      .addCase(fetchAudienceWeeklyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Monthly data
      .addCase(fetchAudienceMonthlyData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAudienceMonthlyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentPeriodData = action.payload.current_period;
        state.previousPeriodData = action.payload.previous_period || [];
      })
      .addCase(fetchAudienceMonthlyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearData } = audienceChartSlice.actions;
export default audienceChartSlice.reducer;
